import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {ContainerBotao, ContainerCarregandoPdf, ContainerContratoLer} from "./styled";
import {Button, CircularProgress, Container, Typography, Link as LinkMui} from "@material-ui/core";
import {AuthContext} from "../../Context/AuthContext";
import {Link} from "react-router-dom";
import useDocumentTitle from '../../hooks/useDocumentTitle';

const isIOS = (): boolean => {
  if (typeof navigator === 'undefined') return false;
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
  );
};

const ContratoLerPage = () => {
    useDocumentTitle("Contrato - Leitura")

    const iframeRef = useRef(null);
    const authContext = useContext(AuthContext);
    const {alunoId, matriculaId, autoescolaId} = authContext.aluno;
    const {id} = authContext.statusContrato;
    const [dinamico, setDinamico] = useState(new Date().getTime());
    const [intervalId, setIntervalId] = useState(0);
    const urlPdf = useMemo(() => `https://storage.googleapis.com/ftp_docs/${autoescolaId}/AlunoContratos/${id === 2 ? 'assinado' : 'original'}-${alunoId}-${matriculaId}.pdf`, [alunoId, matriculaId, id, autoescolaId]);
    const url = useMemo(() => `https://drive.google.com/viewerng/viewer?embedded=true&url=${urlPdf}&time=${dinamico}`, [urlPdf, dinamico]);
    const [isLoading, setIsLoading] = useState(true);

    function carregado(x: any) {
        setIsLoading(false)
    }

    console.log('URL', url);
    

    useEffect(() => {
        if (!iframeRef) return;
        if (!isLoading) {
            clearInterval(intervalId)
            return;
        }
       let id:any= setInterval(() => {
                if (isLoading){
                    setDinamico(new Date().getTime())
                }else {
                    clearInterval(intervalId)
                }
        }, 4000)
        setIntervalId(id)

    }, [iframeRef,isLoading]);


    return (
      <Container style={{ margin: 0, padding: 0, width: '100%' }}>
        <ContainerContratoLer>
          {isIOS() && (
            <Container style={{ display: 'flex', justifyContent: 'center' }}>
              <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 500 }}>
                <Typography style={{textAlign: 'center', fontSize: 12}}>
                  Caso não consiga vizualizar o contrato, clique no link abaixo, depois se estiver de acordo com o contrato, volte e clique no botão "ACEITAR CONTRATO"
                </Typography>
                <Typography
                  component={'a'}
                  target="_blank"
                  href={url}
                  rel="noopener noreferrer"
                >
                  Visualizar o contrato
                </Typography>
              </Container>
            </Container>
          )}

          <iframe
            onLoadCapture={(x) => console.log('onLoadCapture', x)}
            onChangeCapture={(x) => console.log('onChangeCapture', x)}
            onChange={(x) => console.log('onChange', x)}
            onEmptied={(x) => console.log('onEmptied', x)}
            onEmptiedCapture={(x) =>
              console.log('onEmponEmptiedCapturetied', x)
            }
            onLoadedMetadataCapture={(x) => console.log('onLoadedMetadata', x)}
            onError={(x) => console.log('onError', x)}
            ref={iframeRef}
            style={{
              // display: isLoading ? 'none' : 'block'
              height: isLoading ? '0px' : '100%',
            }}
            src={url}
            onLoad={carregado}
            width="100%"
            frameBorder="0"
          ></iframe>

          {isLoading && (
            <ContainerCarregandoPdf>
              <CircularProgress></CircularProgress>
              <h1>Carregando...</h1>
              <Typography variant={'caption'}>
                caso demore, baixe o seu contrato à baixo:
              </Typography>
              <Typography>
                <LinkMui href={urlPdf} target="_blank">
                  Baixar aqui!
                </LinkMui>
              </Typography>
            </ContainerCarregandoPdf>
          )}
        </ContainerContratoLer>

        <ContainerBotao>
          {authContext.statusContrato.id !== 2 && (
            <Button
              component={Link}
              to="/contrato/assinar"
              style={{
                backgroundColor: '#19A597',
                color: 'white',
              }}
              size="large"
              variant="contained"
            >
              ACEITAR CONTRATO
            </Button>
          )}
        </ContainerBotao>
      </Container>
    );


}

export default ContratoLerPage;

